.ReviewsH1{
    text-align: center;
    color: #0C3F6D;
    margin: 50px auto !important;
}

.imgContainer{
    display: flex;
    flex-direction: row;
    width: 80vw;
    margin: 2px auto;
    justify-content: space-evenly;
    overflow: hidden;
}

.img{
    width: 500px;
    height: 500px;
}

.videoContainer{
    box-sizing: border-box;
    width: 30vw;
    height: 30vh;
    /* width: 100%; */
    margin: 10px auto ;
    
}

.img1{
    width: 250px;
    height: 500px;
    margin:  20px 0px;
}

@media screen and (max-width:1000px) {
    .videoContainer{
        width: 80vw;
    }

    .imgContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}