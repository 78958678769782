.imgContainer{
    width: 100px;
    height: 100px;
    margin: 0 auto;

}

.img{
    width: 100%;
    height: 100%;
    border: 2px o;
}


.afterRiviewColWidth{
    width: 100% !important;
}

.text{
    font-size: 10px;
}

@media  screen and (max-width:1000px) {
    
.text{
    text-align: center;
}

}