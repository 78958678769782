.Card{
    /* border: 2px solid red; */
    display: flex;
    
    width: 80vw;
    margin: 20px auto;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 22px;
    box-shadow: 5px 5px 26px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 5px 5px 26px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 5px 5px 26px 0px rgba(0,0,0,0.75);
}

.videoContainer{
    width: 50vw;
    height: 50vh;
    width: 100%;
}

.CardText{
    /* border: 2px solid green; */
    width: 100%;
    margin-left: 20px;
    
}

.CardTexth4{
    background-color: #0C3F6D;
    color: white;
    text-align: center;
}

.greyText{
    font-size: 10px;
    color: rgb(192, 192, 192);
}

@media (max-width:1000px) {
    
    .Card{
       width: 100vw;
       margin:  10px auto;
       padding: 40px;
        flex-direction: column;
    }

    .CardText{
        margin-top: 20px;
        margin-left: 0px;
    }
}