.AsSeenOn{
    width: 200px !important;
    height: 50px !important;
    margin:  0px auto !important;
    margin-top: 50px !important;
}

/* .border{
    border : 2px solid red;
} */

.golden{
    color: #DCA812;
}

.HeroText{
    margin: 50px 20px;
    text-align: center;
}

.HeroText h2{
    font-weight: 200;
}

@media screen and (max-width:1000px) {

    .HeroText h1{
        font-size: 1.4rem;
    }

    .HeroText h2{
        font-size: 0.7rem;
        font-weight: 400;
    }

    .AsSeenOn{
   
        margin-top: 10px !important;
    }

    .HeroText{
        margin-bottom: 10px;
        text-align: center;
    }
    
}