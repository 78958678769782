.headline{
    background-color:#0C3F6D ;
    color: white;
    text-align: center;
    border-radius: 0 0 20px 20px;
    width: 90vw !important;
    padding: 0;
    padding: 10px 0px;
    box-sizing: border-box;
    margin: 0px auto;
}

@media screen and (max-width:1000px) {

    .headline{
        font-size: 0.9rem;
    }
    
}