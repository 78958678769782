.Center{
    text-align: center;
    font-size: 10px;
   
}

.bold{
    font-weight: bold;
}

.footercolor{
    color: rgb(196, 196, 196);
}

.Footer{
    padding: 30px 30px;
}

.footer_link{
    text-align: center;
    /* border: 2px solid red; */
    margin: 0px auto;
    
}

.footer_link a{
    text-decoration: none;
    color: black;
}